///////////////////////////////////////////// trucks
var truck_options = {

    url: function(number) {
        return "/trucks/search_truck_nr_json?query=" + number;
    },


    getValue: "number",
    placeholder: document.documentElement.lang === 'lt' ? 'Pasirinkti' : 'Select',
    adjustWidth: false,

    list: {
        maxNumberOfElements: 10,

        match: {
            enabled: true
        },
        sort: {
            enabled: true
        }
    },
};


let items = document.querySelectorAll('.autocomplete-trucks');

for(let i = items.length - 1; i >= 0; i--){
    $(items[i]).easyAutocomplete(truck_options);
}


///////////////////////////////////////////// trailers

var trailer_options = {

    url: function(number) {
        return "/trailers/search_trailer_nr_json?query=" + number;
    },


    getValue: "number",
    placeholder: document.documentElement.lang === 'lt' ? 'Pasirinkti' : 'Select',
    adjustWidth: false,

    list: {
        maxNumberOfElements: 10,

        match: {
            enabled: true
        },
        sort: {
            enabled: true
        }
    },

};
$( ".autocomplete-trailers" ).easyAutocomplete(trailer_options);
