require('./bootstrap')

// http://easyautocomplete.com/guide
import easyAutocomplete from 'easy-autocomplete'
window.easyAutocomplete = easyAutocomplete
require('./autocomplete')


import 'jquery-datetimepicker'
require('./datetimepicker')


require('./defenition_combobox')

import Alpine from 'alpinejs';

window.Alpine = Alpine

Alpine.start()
