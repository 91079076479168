$(".truck_plan_main").each(function () {
    child_country_show_hide(this);

    $(this).change(function () {
        child_country_show_hide(this);
    });

});


function child_country_show_hide(elem) {
    if (elem.value != 'register') {
        $(elem).parent().find('.truck_plan_country').hide();
    } else {
        $(elem).parent().find('.truck_plan_country').show();
    }
}
